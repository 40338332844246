<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>{{ $t("global.campaigns") }}</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="pending_campaigns.length > 0" class="pending-wrap">
            <h3>Kampagnen die auf Freischaltung warten...</h3>
            <table class="table">
              <thead>
                <tr>
                  <th>Medium</th>
                  <th>{{ $t("global.name") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="campaign in pending_campaigns" v-bind:key="campaign.uuid">
                  <td width="30%">
                    <div v-if="campaign.advertisment">
                      <div v-if="campaign.advertisment.thumbnail">
                        <a :href="campaign.advertisment.asset_file" target="_blank"><img :src="campaign.advertisment.thumbnail" class="media-element" /></a>
                      </div>
                      <div v-else>
                        <a :href="campaign.advertisment.asset_file" target="_blank">
                          <img :src="require('../../../assets/themes/' + _self.$theme + '/placeholder.gif')" style="max-width: 100%"
                        /></a>
                      </div>
                    </div>
                  </td>
                  <td width="60%">{{ campaign.name }}</td>
                  <td width="10%" style="text-align: right">
                    <router-link :to="'/admin/campaigns/' + campaign.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-wrap" style="margin-bottom: 20px">
            <input
              v-model="search_phrase"
              v-on:input="search_handler"
              class="form-input"
              type="text"
              name="search_phrase"
              id="search_phrase"
              placeholder="Suchbegriff eingeben..."
            />
          </div>

          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Medium</th>
                  <th>{{ $t("global.name") }}</th>
                  <th>Budget</th>
                  <th>Account</th>
                  <th>Stats</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="campaign in campaigns" v-bind:key="campaign.uuid">
                  <td width="5%">
                    <span v-if="campaign.status == 'draft'" class="status status-grey"></span>
                    <span v-if="campaign.status == 'creating'" class="status status-grey"></span>
                    <span v-if="campaign.status == 'created'" class="status status-grey"></span>
                    <span v-if="campaign.status == 'pending'" class="status status-yellow"></span>
                    <span v-if="campaign.status == 'running'" class="status status-green"></span>
                    <span v-if="campaign.status == 'stopped'" class="status status-red"></span>
                    <span v-if="campaign.status == 'paused'" class="status status-red"></span>
                    <span v-if="campaign.status == 'blocked'" class="status status-red"></span>
                    <span v-if="campaign.status == 'deleted'" class="status status-black"></span>
                  </td>
                  <td width="20%">
                    <div v-if="campaign.advertisment">
                      <div v-if="campaign.advertisment.thumbnail">
                        <a :href="campaign.advertisment.asset_file" target="_blank"
                          ><img :src="campaign.advertisment.thumbnail" style="max-width: 100%" class="media-element"
                        /></a>
                      </div>
                      <div v-else>
                        <a :href="campaign.advertisment.asset_file" target="_blank">
                          <img :src="require('../../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                        </a>
                      </div>
                    </div>
                  </td>
                  <td width="25%">
                    <router-link :to="'/admin/campaigns/' + campaign.uuid" style="color: #444; font-weight: 600">{{ campaign.name }}</router-link>
                  </td>
                  <td width="10%">
                    {{
                      new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(campaign.budget)
                    }}
                  </td>
                  <td width="20%">
                    <router-link :to="'/admin/users/' + campaign.user.uuid" style="color: #444; font-weight: 600"
                      >{{ campaign.user.first_name }} {{ campaign.user.last_name }}</router-link
                    >
                  </td>
                  <td width="20%">{{ campaign.impressions }} | {{ campaign.qr_scan_count }}</td>
                  <td width="10%" style="text-align: right">
                    <router-link :to="'/admin/campaigns/' + campaign.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row row-gutter-20" style="margin-top: 20px">
              <div class="col-12">
                <a v-if="pagination.links.prev" @click="prev_page" class="button button-ci button-100 button-round button-small">Vorherige Seite</a>
                <a v-else>-</a>
              </div>
              <div class="col-12" style="text-align: right">
                <a v-if="pagination.links.next" @click="next_page" class="button button-ci button-100 button-round button-small">Nächste Seite</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      pending_campaigns: [],
      campaigns: [],
      pagination: {},
      loading: true,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/campaigns?status=pending", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.pending_campaigns = response.data.campaigns;
        });
    },
    get_campaigns(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/campaigns?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.campaigns = response.data.campaigns;
          this.pagination = response.data.meta.pagination;
          this.loading = false;
        });
    },
    search_handler() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/campaigns?search_phrase=" + this.search_phrase, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.campaigns = response.data.campaigns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    next_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.next.split("=")[1]);
    },
    prev_page() {
      this.loading = true;
      this.get_campaigns(this.pagination.links.prev.split("=")[1]);
    },
  },
  mounted() {
    this.get_campaigns(1);
    this.get_data();
  },
};
</script>

<style lang="scss">
.pending-wrap {
  border: 1px solid #444;
  margin-bottom: 20px;

  h3 {
    width: 100%;
    padding: 14px;
    margin: 0;
    background: #444;
    color: #fff;
  }
}

.media-element {
  max-width: 100%;
  max-height: 140px;
  display: block;
}
</style>
